var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("header", [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "Search invoice", size: "mini" },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _c(
              "el-radio-group",
              {
                attrs: { disabled: !!_vm.search.length, size: "mini" },
                model: {
                  value: _vm.group_by,
                  callback: function ($$v) {
                    _vm.group_by = $$v
                  },
                  expression: "group_by",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "linking" } }, [
                  _vm._v(" Unlinked "),
                ]),
                _c("el-radio-button", { attrs: { label: "due" } }, [
                  _vm._v(" Due "),
                ]),
                _c("el-radio-button", { attrs: { label: "all" } }, [
                  _vm._v(" All "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("div", { staticClass: "ex-GST" }, [
              _c("span", [_vm._v("(Prices ex. GST)")]),
              _vm.sysInvoices
                ? _c("span", [
                    _vm._v(
                      " Last updated " +
                        _vm._s(
                          _vm._f("timeAgo")(
                            _vm._f("timestamp2moment")(
                              _vm.sysInvoices.last_updated?.seconds
                            )
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "el-button",
              {
                attrs: { size: "mini", disabled: _vm.loading },
                on: { click: _vm.fetchInvoices },
              },
              [_vm._v(" Refresh ")]
            ),
          ],
          1
        ),
      ]),
      _vm.loading
        ? _c("loader")
        : _c(
            "div",
            { staticClass: "inner" },
            [
              !_vm.paginated_invoices.length && !_vm.loading
                ? _c("placeholder", [
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(" " + _vm._s(_vm.placeholder_content.title) + " "),
                    ]),
                    _c(
                      "span",
                      { attrs: { slot: "subtitle" }, slot: "subtitle" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.placeholder_content.subtitle) + " "
                        ),
                      ]
                    ),
                  ])
                : _c(
                    "ul",
                    { staticClass: "invoices-list" },
                    _vm._l(_vm.paginated_invoices, function (invoice) {
                      return _c("invoice-block", {
                        key: invoice.id,
                        attrs: { invoice: invoice },
                      })
                    }),
                    1
                  ),
              _c("el-pagination", {
                attrs: {
                  layout: "prev, pager, next",
                  "current-page": _vm.current_page,
                  "page-count": _vm.page_count,
                  "page-size": _vm.page_size,
                  "hide-on-single-page": true,
                },
                on: { "current-change": (val) => (_vm.current_page = val) },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }